import React from 'react';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import { useMsal } from '@azure/msal-react';
import * as styles from './navbar-styles';

function LeftDrawer(props) {
  const { drawerOpen, toggleDrawer, accounts } = props;
  const navigate = useNavigate();
  const { instance } = useMsal();

  const handleLogout = () => {
    const currentAccount = instance.getAccountByHomeId(
      accounts[0].homeAccountId,
    );

    instance.logout({
      account: currentAccount,
      postLogoutRedirectUri: window.location.origin,
      authority:
        'https://login.microsoftonline.com/18ac49d1-d4dc-41a0-a707-ee1acbf0e57b',
    });
  };

  return (
    <div>
      <React.Fragment>
        <Drawer
          anchor={'left'}
          open={drawerOpen}
          onClose={toggleDrawer()}
          sx={styles.leftDrawerDrawer}
        >
          <div
            style={styles.leftDrawerListWrapper}
            role="presentation"
            onClick={toggleDrawer()}
            onKeyDown={toggleDrawer()}
          >
            <List sx={styles.leftDrawerList}>
              <div>
                <ListItem sx={styles.leftDrawerDrawerTitle}>
                  <Typography variant="h6">Mio stores</Typography>
                </ListItem>
                <ListItemButton onClick={() => navigate('/')}>
                  <ListItemText primary={'Hem'} />
                </ListItemButton>
                <Divider variant="middle" />
                <ListItemButton onClick={() => navigate('/butiker')}>
                  <ListItemText primary={'Butiker'} />
                </ListItemButton>
                <Divider variant="middle" />
                <ListItemButton onClick={() => navigate('/postnummer')}>
                  <ListItemText primary={'Postnummer'} />
                </ListItemButton>
              </div>
              <div>
                <ListItemButton sx={styles.leftDrawerAccount}>
                  <ListItemText primary={accounts[0].name} />
                  <Button
                    style={{ color: '#d32f2f', borderColor: '#d32f2f' }}
                    variant="outlined"
                    size="small"
                    sx={styles.leftDrawerLogoutButton}
                    onClick={handleLogout}
                  >
                    logga ut
                  </Button>
                </ListItemButton>
              </div>
            </List>
          </div>
        </Drawer>
      </React.Fragment>
    </div>
  );
}

export default LeftDrawer;
